import React from 'react'
import { gql } from '@apollo/client'
import { initializeApollo } from 'gql/client'
import { PAGINA } from 'utils/queries'
import LoginTemplate from 'components/templates/LoginTemplate/LoginTemplate'
import { NextWrapper } from 'data-fetching-wrapper'

const LoginPage = ({ pagina }) => <LoginTemplate pagina={pagina} />

export const getStaticProps = NextWrapper.getStaticProps(async ({ locale }) => {
  const client = initializeApollo(null, null, null, locale)

  const {
    data: { pagina },
  } = await client.query({
    query: gql`
      ${PAGINA.replace('__chiave__', 'login')}
    `,
  })

  if (!pagina) {
    return {
      notFound: true,
    }
  }

  return {
    props: { pagina }, // will be passed to the page component as props
    revalidate: 60 * 10, // In seconds
  }
}, '/login/')

export default LoginPage
