import React from 'react'
import useTrans from 'hooks/useTrans'
import { useRouter } from 'next/router'
import { useSocialLogin, useLocalStorage } from 'hooks'
import { Button, NotificationBar } from 'components/atoms'
import { FormLogin, Page } from 'components/organisms'
import { Alert } from 'components/atoms/Icons'


const LoginTemplate = ({ pagina }) => {
  const { FacebookLoginButton, GoogleLoginButton, error } = useSocialLogin()
  const t = useTrans()
  const [showSocialLogin, setShowSocialLogin] = useLocalStorage('showSocialLogin', false)
  
  return (
    <Page
      pagina={pagina}
      hideBreadcrumbs
      contentStyle="basic"
      className="login-template"
      containerVariant="xs"
    >
      <FormLogin />
      <p className="login-template__register">
        {t("Non hai un Account?")}{' '}
        <Button href="/registrazione" label={t('Registrati')} variant="ghost" size="sm" />, {t("oppure")}
      </p>
     {showSocialLogin && <div className="login-template__social-buttons">
        {error && (
          <NotificationBar
            label={error}
            variant="alert"
            icon={<Alert />}
            className="form-login__error"
          />
        )}
        <FacebookLoginButton />
        <GoogleLoginButton />
      </div>}
    </Page> 
  ) 
}

export default LoginTemplate
